// enums.ts
export enum PeriodEnum {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum OverlayEnum {
    AVERAGE = 'AVERAGE',
    TREND = 'TREND',
    DAY_NIGHT = 'DAY_NIGHT',
}

export enum ChartTypeEnum {
    LINE="LINE",
    BAR="BAR"
}

export enum GroupEnum {
    ACCOUNT="ACCOUNT",
    SITE_GROUP="SITE_GROUP",
    SUPPLY_TYPE="SUPPLY_TYPE",
    SITE="SITE",
    TOTAL="TOTAL"
}