import { Box, AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { hhMonthlyDataType } from "../pages/Supply"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useMemo } from "react";

type HHDataProps = {
  hhData: hhMonthlyDataType[] | undefined
}

function HHGraph({hhData}: HHDataProps) {

  const unit = useMemo(() => {
    if (!hhData) return 'kWh';

    const maxValue = hhData.reduce((max, obj) => {
      return obj.month_total > max ? obj.month_total : max;
    }, 0);

    if (maxValue >= 1e6) {
        return 'GWh';
    } else if (maxValue >= 1e3) {
        return 'MWh';
    } else {
        return 'kWh';
    }
  }, [hhData]);


  const numberFormatter = (value: number) => {
      if (unit === 'GWh') {
          return `${(value / 1e6).toFixed(2)}${unit}`; // Format as GWh
      } else if (unit === 'MWh') {
          return `${(value / 1e3).toFixed(2)}${unit}`; // Format as MWh
      } else {
          return `${value.toFixed(2)}${unit}`; // Format as kWh
      }
  };


  let noDataMessage;
  if (hhData === undefined) noDataMessage = <Spinner /> // loading hh data
  if (hhData && hhData.length < 1) noDataMessage = "No consumption data available." // hh data returns empty
  if (noDataMessage) return (
    <Box  position='relative' border={"1px"} borderRadius={"10px"} borderColor={"#6A7D80"} width="100%" height="100%">
      <AbsoluteCenter><p>{noDataMessage}</p></AbsoluteCenter>
    </Box>
  )

  return (
    // !!! XAxis and YAxis currently throw warnings in the console, recharts devs fixing this and if needed we can use alpha build to solve for now
    <ResponsiveContainer width="100%" height="100%" >
      <LineChart data={hhData} margin={{left: 40 }}>
        <CartesianGrid stroke="#ccc" />
        <YAxis tickFormatter={numberFormatter} />
        <XAxis dataKey="month"/>
        <Line type="monotone" dataKey="month_total" name="Consumption" stroke="#8884d8" />
        <Tooltip formatter={numberFormatter} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default HHGraph