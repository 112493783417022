import { useState, useEffect, useContext } from "react"
import { NotificationContextType, SupplyType } from "../types";
import HHGraph from "../components/HHGraph";
import MeterReadSubmission from "../components/MeterReadSubmission";
import Agreements from "./Agreements";
import { Link } from "wouter";
import { Box, Icon, Spinner, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Th, Thead, Tr, useDisclosure, VStack } from "@chakra-ui/react";
import { useApi } from "../hooks/useApi";
import { NotificationContext } from "../components/NotificationPane";
import SupplyIcon from "../components/SupplyIcon";
import { FaTicketAlt } from "react-icons/fa";
import { getCEDString } from "../components/CellRenderers";
import TicketsDrawer from "../components/TicketsDrawer";

export type hhMonthlyDataType = {
  supply_number : string;
  month : string;
  month_total : number;
}

type SupplyProps = {
  id: string
}

function Supply({id}: SupplyProps) {
  const { isOpen: isTicketsOpen, onOpen: onTicketsOpen, onClose: onTicketsClose } = useDisclosure();
  const { ready, getSupply, getSupplyHHData } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [supplyData, setSupplyData] = useState<SupplyType | undefined>(undefined);
  const [hhData, setHhData] = useState<hhMonthlyDataType[] | undefined>(undefined);

  useEffect(() => {
    async function loadSupply() {
      try {
        const response = await getSupply(id);
        setSupplyData(response);
      } catch(err) {
        console.log(err)
        pushNotification("Could not load supply.", "error")
      }
    }

    if (ready && id)
      loadSupply();
  }, [ready, id]);

  useEffect(() => {
    async function loadHHData(supplyNumber: string) {
      try {
        const response = await getSupplyHHData(supplyNumber);
        setHhData(response);
      } catch(err) {
        pushNotification("Could not load chart data.", "error")
      }
    }

    if (ready && supplyData?.supply_number) 
      loadHHData(supplyData.supply_number);

  }, [ready, supplyData]);

  if (supplyData === undefined) return <Spinner />
  return (
    <>
      <TicketsDrawer onClose={onTicketsClose} isOpen={isTicketsOpen} supplyid={supplyData.supply_id}></TicketsDrawer>
      <Tabs backgroundColor={"white"} isLazy align='start' variant='enclosed' colorScheme='purple'>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Contracts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box></Box>
            <VStack margin={4}>
              <Box w="100%">
                <TableContainer>
                  <Table variant='simple'>
                    <Thead>
                      <Tr>
                        <Th colSpan={2}><SupplyIcon utility={supplyData.supply_type}/>{supplyData.supply_verbose}</Th>
                      </Tr>
                      <Tr>
                        <Th><MeterReadSubmission supplyData={supplyData} /></Th>
                        <Th><Link color='teal.500' href='#' onClick={onTicketsOpen}><Icon as={FaTicketAlt}/> Ticket</Link></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>Account</Td>
                        <Td>{supplyData.account_name}</Td>
                      </Tr>
                      <Tr>
                        <Td>Site</Td>
                        <Td>{supplyData.site_address}</Td>
                      </Tr>
                      <Tr>
                        <Td>Contract End Date</Td>
                        <Td>{getCEDString(((supplyData.contract_end_date) ? new Date(supplyData.contract_end_date) : null), supplyData.rollingcontract)}</Td>
                      </Tr>
                      <Tr>
                        <Td>Current Consumption</Td>
                        <Td>{supplyData.eb_currentconsumption}</Td>
                      </Tr>
                      <Tr>
                        <Td>Supply Type</Td>
                        <Td>{supplyData.supply_type}</Td>
                      </Tr>
                      <Tr>
                        <Td>Supply Sub-Type</Td>
                        <Td>{supplyData.supply_subtype}</Td>
                      </Tr>
                      <Tr>
                        <Td>Meter Serial Number</Td>
                        <Td>{supplyData.eb_meterserialnumber}</Td>
                      </Tr>
                      <Tr>
                        <Td>Current Supplier</Td>
                        <Td>{supplyData.supply_supplier}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box w="100%" h={250}>
                <HHGraph hhData={hhData} />
              </Box>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Agreements supplyId={id} />
          </TabPanel>
        </TabPanels>
      </Tabs> 
    </>
  )
}

export default Supply
